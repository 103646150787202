<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" :paginator="true" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="nombre" header="Nombre" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="pag" header="Páginas" :sortable="true" headerStyle="width: 80px">
						<template #body="slotProps">
							{{slotProps.data.pag}}
						</template>
					</Column>
					<Column field="usuarios" header="Usuarios" :sortable="true" headerStyle="width: 80px">
						<template #body="slotProps">
							{{slotProps.data.usuarios}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 80px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger"  v-else>No Activo</span>
						</template>
					</Column>
					<Column :header="Acciones" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="Editar(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-unlock" class="p-button-rounded p-button-warning mr-2" @click="EditarPermiso(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Confirmar(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="fluid">
					<div class="fluid">       
						<div class="grid fluid mt-3">
							<div class="col-9">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="!product.nombre">Es requirido</small>
							</div>
							<div class="col-3">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="col-12">
									<h5>Páginas</h5> 
									<PickList v-model="product.paginas" dataKey="code">
										<template #sourceHeader>
											Páginas
										</template>
										<template #targetHeader>
											Activos
										</template>
										<template #item="slotProps">
											<div>{{slotProps.item.modulo}} --> {{slotProps.item.nombre}}</div>
										</template>
									</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="permisosDialog" :style="{width: size}" header="Permisos" :modal="true" class="p-fluid">
                    <div class="field">
						<h5>Rol: {{permisos.nombre}}</h5>
						<div class="grid">
									<div class="col-4"><strong>Módulos</strong></div>
									<div class="col-5"><strong>Páginas</strong></div>
									<div class="col-1"><strong>Editar</strong></div>
									<div class="col-1"><strong>Insertar</strong></div>
									<div class="col-1"><strong>Borrar</strong></div>
							<div class="col-12"  v-for="(stats, index) in permisos.permisologia" :key="index">
								<div class="grid">
									<div class="col-4">
										{{stats.modulo}}
									</div>
									<div class="col-5">
										{{stats.pagina}}
									</div>
									<div class="col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].edit" />
									</div>
									<div class="col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].insert" />
									</div>
									<div class="col-1">
										<InputSwitch :id="index" v-model="permisos.permisologia[index].delete" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="GuardarPermiso()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="permisosDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" style="width: 30vw" :header="'Eliminar ID'+product.id" :modal="true">
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
					</template>
				</Dialog>
			
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Roles');

	export default {
		data() {
			return {
				nuevo: false,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				permisos: {},
				niveles: null,
				productDialog: false,
				permisosDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {	
				this.permisos = null;
				this.products = null;
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result[0];
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
					this.$store.commit('Loading');
				}); 
			},
			Nuevo() {
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
				this.productDialog = true;
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
				this.productDialog = true;
			},
			EditarPermiso(permiso) {
				this.permisos = {...permiso};
				this.permisosDialog = true;
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Guardar() {
				if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nombre de Rol', life: 5000});
				} else {
					var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}
					Consulta.Procesar(caso,{
							product: 	this.product,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							this.$toast.add(Consulta.Mens(response.result));
							if (response.result) {
								this.Mostrar();
								this.productDialog = false;
								this.product = {};
							}
						});
				}
			},
			GuardarPermiso() {
				this.permisosDialog = false;
				Consulta.Procesar('UPD2',{
					product: 	this.permisos,
				}).then(response => {
					this.$store.commit('Loading');
					//this.$store.state.error =response;
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.permisosDialog = false;
				this.permisos = {};
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Mostrar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
